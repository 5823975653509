html{
    margin: 0px; 
    padding: 0px;
    height: 100%;
}
body {
    margin: 0px;
    padding: 0px;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.options {
    overflow: auto;
    background-color: white;
    border-left: 1px solid lightgray;
    padding: 10px;
}

#root{
    margin: 0px;
    padding: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.option-btn{
    display: none;
}

@media screen and (max-width: 600px) {
    .options {
        position: fixed;
        right: 0px;
        left: 0px;
        bottom: 0px;
        background-color: white;
        display: none;
    }
    .option-btn{
        display: inline;
    }
    .dropzone{
        display: none;
        flex: 1;
    }
}

.landing-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.big-btn{
    padding: 15px 30px;
    font-size: 35px;
    color: white;
    border: none;
    border-radius: 15px;
    background-color: purple;
    background-image: linear-gradient(-45deg, #ff3333, #3385ff);
    box-shadow: 0px 10px 20px 0px rebeccapurple;
    outline: none;
    cursor: pointer;
}

.big-btn:hover{
    background-image: linear-gradient( #FF3D77, #FF3D77);
}

.page-header{
    font-size: 20px;
    color: white;
    background-image: linear-gradient(-45deg, #FF3D77, #338AFF);
    box-shadow: 0px 10px 20px 0px rebeccapurple;
    padding: 20px;
}

.dropzone{
    padding: 30px;
    font-size: 15px;
}

.button{
    padding: 10px;
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 20px;
    background-color: dodgerblue;
    outline: none;
    cursor: pointer;
}

.busy{
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background: rgb(0,0,0,0.5);
    color: white;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    display: none;
}