footer {
    text-align: center;
    padding: 25px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
}

footer p {
    margin: 0;
    padding-right: 5px;
}

.footer-link {
    color: inherit;
    padding-left: 5px;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: none;
}