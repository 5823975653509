.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f8f9fa;   */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  margin: 0;
}

.logo span {
  font-size: 20px;
  margin-right: 10px;
  font-weight: bold;
}

.navbar a {  
  text-decoration: none; /* 添加这一行 */  
  color: inherit;
}  