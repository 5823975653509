/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
  margin: 0;
} */

.parent {
  display: 'flex';
  flex-direction: 'column';
  overflow: auto;
}

.navbar {
  width: 100%;
  padding: 15px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.navbar .logo {
  display: flex;
  align-items: center;
}

.navbar .logo img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.navbar .title {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  padding-top: 120px;
  background-color: #fff;
  margin: 0 auto;
}

.h1 {
  color: #333;
  position: relative;
}

.h2 {
  align-items: center;
  position: relative;
  text-align: center;
  justify-content: center;
}

.p {
  color: #666;
  line-height: 1.6;
  text-align: left;
}